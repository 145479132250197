
import { defineComponent } from "vue";

export default defineComponent({
  name: "EmailConfirmedSection",
  data() {
    return {};
  },
  components: {},
  mounted() {
    this.sendForm();
    this.verifyEmail();
  },
  methods: {
    sendForm() {
      this.$store.dispatch("getNFTs/updateUser").then(
        (response) => {
          this.email = response.data.email;
          this.sendVerificationEmail();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    sendVerificationEmail() {
      this.$store
        .dispatch("auth/verificationEmail", {
          email: this.email,
        })
        .then(
          (res) => {
            console.log(res);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    verifyEmail() {
      this.$store
        .dispatch("auth/verifyEmail", {
          token: this.$route.query.token,
        })
        .then(
          (res) => {
            console.log(res);
            this.$router.push("/dashboard/profile");
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
});
